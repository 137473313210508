/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { ResponsiveCalendar } from "./ResponsiveCalendar";
import PillCard from "../shared/cards/PillCard";
import { UserMenu } from "../../layout/UserMenu";
import { PersonalDataModel } from "../../../core/models/personal-data-model";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { getAdiAppointments } from "../../../core/services/job-service";
import moment from "moment";
import EmptyState from "../shared/elements/EmptyState";
import { PrismicHeaderLinks } from "./action-centre/PrismicHeaderLinks";

const DashboardHeader: FC = () => {
  const personalData: PersonalDataModel = useSelector<RootState>(
    ({ auth }) => auth.personalData,
    shallowEqual
  ) as PersonalDataModel;

  const [headerExpanded, setHeaderExpanded] = useState(false);
  const [appointments, setAppointments] = useState<any>([]);
  const [allAppointments, setAllAppointments] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const today = moment(new Date()).format("YYYY-MM-DD");
  const date = new Date();
  const [loading, setLoading] = useState<boolean>(true);
  const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile);

  useEffect(() => {
    adiAppointments(today);
  }, []);

  const adiAppointments = (date: any) => {
    setSelectedDate(moment(date).format("DD-MM-YYYY"));
    setLoading(true);
    setAllAppointments([]);
    setAppointments([]);
    getAdiAppointments({ date: date })
      .then((res: any) => {
        setLoading(false);
        setAllAppointments(res.data.results);
        setAppointments(res.data.results.slice(0, 2));
      })
      .catch((err: any) => {});
  };

  return (
    <>
      <div
        className={` ${
          headerExpanded
            ? "fixed inset-x-0 top-0 bottom-0 w-full z-[500] h-[calc(100vh-150px)] justify-between"
            : ""
        } flex flex-col pb-3 bg-yellow sm:mt-0 -mt-6`}
      >
        <div>
          <div
            className={` ${
              headerExpanded
                ? isMobile
                  ? "pt-16"
                  : "pt-9"
                : isMobile
                ? "pt-10"
                : "pt-3"
            } bg-darkBlue`}
          >
            <div className="px-5 ">
              <UserMenu personalData={personalData} date={date} />
            </div>
            <div>
              <PrismicHeaderLinks />
            </div>
          </div>
          <ResponsiveCalendar handleClick={adiAppointments} />
          <div
            className={` ${
              headerExpanded
                ? "max-h-[70vh] overflow-y-auto"
                : " max-h-[165px] overflow-y-hidden"
            } px-5 mt-[18px] flex flex-col`}
          >
            {/* {appointmentsRows} */}

            {loading ? (
              <PillCard loading={loading} />
            ) : appointments?.length == 0 ? (
              <div className="-my-8">
                <EmptyState
                  description=""
                  title="No appointments on this day"
                />
              </div>
            ) : (
              <>
                {appointments.map(function (appointment: any, key: any) {
                  return (
                    <PillCard
                      title={appointment.studentName}
                      key={appointment.id}
                      linkURL={
                        appointment.isLesson
                          ? `/ADI/lessons?selected=${selectedDate}`
                          : `/ADI/tests?selected=${selectedDate}`
                      }
                      typeIcon={
                        appointment.isLesson
                          ? "pmf-icon-wheel"
                          : "pmf-icon-test"
                      }
                      data={[
                        {
                          Icon: "pmf-icon-map-pin",
                          value: appointment.postalCode,
                        },
                        {
                          Icon: "pmf-icon-clock",
                          value:
                            appointment.timeFrom + " to " + appointment.timeTo,
                        },
                      ]}
                      colour={appointment.isLesson ? "yellow" : "darkBlue"}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
        {allAppointments.length > 0 && (
          <div className="mt-3 flex flex-col">
            <button
              className="w-full flex justify-center"
              onClick={() => {
                headerExpanded === false
                  ? setAppointments(allAppointments)
                  : setAppointments(appointments);
                setHeaderExpanded(!headerExpanded);
              }}
            >
              {allAppointments.length > 2 && (
                <ChevronDownIcon
                  className={`${
                    headerExpanded ? "rotate-180 transform" : ""
                  } h-6 w-6 text-darkBlue bg-yellow border-yellow`}
                />
              )}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export { DashboardHeader };
